@import "assets/styles/Global/variables";

.email-collect-container {
  @include full-viewport-size;
  @include flex($direction: column, $justify: center, $align: center);
  position: relative;

  .content-box {
    @include flex($direction: column, $align: center, $justify: space-around);
    gap: 2rem;
    padding: 2rem 4rem;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    min-width: 30rem;

    .error-logo {
      width: 4.5rem;
    }

    @include for-mobile {
      min-width: 10rem;
      width: 90%;
      padding: 1rem;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .error-logo {
        width: 4rem;
        margin-bottom: 0;
      }
    }

    .email-form {
      @include flex($direction: column, $align: center, $justify: space-around);
      width: 100%;

      p {
        font-size: $vg-font-size-small;
      }

      label {
        align-self: flex-start;
        color: black;
        font-size: 0.8rem;
        line-height: 1.4rem;
        width: 100%;
      }
      input {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid #000000;
        border-radius: 0;
        font-size: 0.8rem;
        line-height: 0.8rem;
        margin: 0;
        min-height: 2rem;
        outline: none;
        padding: 0;
        width: 100%;
        -webkit-appearance: none;
      }

      button {
        margin-top: 0.6rem;
        padding: 0.7rem 1.2rem;
        background-color: $vg-aqua-dark;
        color: $vg-white;
        font-size: $vg-font-size-small;
        font-weight: $vg-font-weight-semi-bold;
        border: 2px solid $vg-aqua-dark;
        border-radius: 5px;
        width: 100%;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        &:disabled {
          opacity: 0.8;
          cursor: not-allowed;
        }
      }
    }
  }

  .app-logo-container {
    @include flex($direction: row, $justify: center);
    position: absolute;
    top: 4rem;

    img {
      width: 100px;
    }

    @include for-mobile {
      display: none;
    }

    @include for-small-computer {
      display: none;
    }
  }

  &.dark {
    .content-box {
      background: rgba(0, 0, 0, 0.6);
    }
    .email-form {
      p,
      input,
      label {
        color: $vg-white;
      }
      input {
        border-bottom: 1px solid $vg-white;
      }

      button {
        background-color: $vg-dark-navy;
        border: 2px solid $vg-dark-navy;
      }
    }
  }
}
