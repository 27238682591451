@import "assets/styles/Global/variables";

.initializing-container {
  position: absolute;
  transition: opacity 0.5s ease-in-out;

  @include flex($direction: column, $justify: center, $align: center);

  .content-box {
    @include flex($direction: column, $align: center, $justify: space-around);
    gap: 2rem;
    padding: 3rem 4rem;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    min-width: 250px;

    @include for-mobile {
      min-width: auto;
      padding: 2rem 1rem;
      gap: 1rem;
    }

    p {
      color: $vg-purple-light;
      font-size: $vg-font-size-medium;
      white-space: pre-wrap;
      text-align: center;
      > span {
        font-weight: 300;
        font-size: $vg-font-size-small;
      }
    }

    .app-streaming-progress-bar {
      width: 300px;
      background-color: rgba(235, 241, 255, 1);
      position: relative;
      height: 4px;
      border-radius: 50px;
      border: none;
      overflow: hidden;
      box-sizing: border-box;
    }

    .progress {
      background-color: $vg-purple-light;
      border-radius: 2px;
      height: 4px;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 50px;
      left: 50%;
      box-sizing: border-box;
      animation: loadingAnimation 2s linear 9999999;
    }
  }

  .app-logo {
    width: 120px;

    @include for-mobile {
      width: 80px;
    }
  }
  .remaining-time-info {
    color: $vg-gray-3;
    position: absolute;
    bottom: 1.5rem;
    font-size: 0.85rem;
    font-weight: 300;
    @include for-short-screen {
      display: none;
    }
  }
  &.dark {
    .content-box {
      background: rgba(0, 0, 0, 0.3);
    }
    p:not(.remaining-time-info) {
      color: $vg-white;
    }
    .progress {
      background-color: $vg-purple-main;
    }
  }
}

@keyframes loadingAnimation {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
