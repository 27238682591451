@import "assets/styles/Global/variables";

.bg-container {
  @include full-viewport-size;
  @include flex($direction: column, $justify: center);
  background-image: url("../../assets/images/light-bg.svg");
  background-size: cover;
  padding: 0;

  &.dark {
    background-image: url("../../assets/images/dark-bg.jpg");
  }
}
