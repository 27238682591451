@import "./Global/_variables";
@import "./Global/reset";

@font-face {
  font-family: "vagonRails";
  font-weight: 700;
  src: local("vagonRails"),
    url("../fonts/vagonRailsUI-Bold.otf") format("truetype");
}

@font-face {
  font-family: "vagonRails";
  font-weight: 600;
  src: local("vagonRails"),
    url("../fonts/vagonRailsUI-Semibold.otf") format("truetype");
}

@font-face {
  font-family: "vagonRails";
  font-weight: 500;
  src: local("vagonRails"),
    url("../fonts/vagonRailsUI-Medium.otf") format("truetype");
}

@font-face {
  font-family: "vagonRails";
  font-weight: 400;
  src: local("vagonRails"),
    url("../fonts/vagonRailsUI-Regular.otf") format("truetype");
}

@font-face {
  font-family: "vagonRails";
  font-weight: 300;
  src: local("vagonRails"),
    url("../fonts/vagonRailsUI-Light.otf") format("truetype");
}

html {
  font-size: $widescreen-1-rem-size;
  @include for-mobile {
    font-size: $mobile-1-rem-size;
  }
  @include for-small-computer {
    font-size: $mobile-1-rem-size;
  }
  @include for-computer {
    font-size: $computer-1-rem-size;
  }
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: $vg-black;
  color: $vg-black;
  font-family: $default-font-family;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  font-kerning: normal;
  height: -webkit-fill-available;
}

button,
input,
textarea {
  font-family: $default-font-family;
}

#root {
  height: initial;
}

a {
  color: $vg-purple-main;
  outline: none;
  text-decoration: none;
  font-weight: $vg-font-weight-semi-bold;
  &:active {
    color: $vg-purple-light;
  }
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  border-radius: 1rem;
  background-color: $vg-gray-6;
}
::-webkit-scrollbar-thumb {
  width: 2px;
  border-radius: 1rem;
  background-color: $vg-gray-5;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.intercom-lightweight-app,
.intercom-app.intercom-app {
  .intercom-lightweight-app-launcher,
  .intercom-launcher-frame {
    bottom: 5px;
    right: 5px;
  }
}

.loader-container {
  &.big {
    font-size: 2rem;
  }

  &.centered {
    @include full-content-width;
    @include flex;
    height: inherit;
  }

  .description {
    font-size: 1rem;
    font-weight: 500;
    padding: 1rem 0;
  }
}

@include for-mobile {
  .hide-on-mobile {
    display: none !important;
  }
}

.hidden {
  display: none;
}

.mobile-only {
  @include for-not-mobile {
    display: none !important;
  }
  @include for-mobile {
    display: inherit;
  }
}
.small-screen-only {
  display: none;

  @include for-small-screen {
    display: inherit;
  }
  @include for-mobile {
    display: inherit;
  }
}

.mobile-only-hide {
  display: inherit;

  @include for-mobile {
    display: none !important;
  }
}

.baby-blue {
  color: $vg-blue-baby;
}

.purple-main {
  color: $vg-purple-main;
}

.red {
  color: $red;
}

.bold {
  font-weight: 600;
}

.popup-item-container {
  position: relative;
}

.popup-item {
  position: absolute;
  box-shadow: 0 13px 26px 0 rgba(0, 0, 0, 0.06);
  background-color: $soft-grey;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  border-radius: 1rem;
  margin-left: -3em;
  padding: 2rem;

  table {
    width: 100%;
    margin: 0;
    font-weight: 500;
    th,
    .tr-end {
      color: $text-color-extra-light;
      font-weight: 600;
    }
    tr {
      height: 1.5rem;
      border: none;
    }
    td {
      font-weight: 500;
    }
  }

  .information {
    color: #6285f4;
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

.grecaptcha-badge {
  top: 14px;
}

.background-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.65);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1100;
}

.Toastify__toast-container {
  .Toastify__toast {
    border-radius: 5px;
    background-color: $vg-notification-bg;
    color: $vg-white;
  }
  .Toastify__toast-body {
    font-weight: $vg-font-weight-light;
    font-size: $vg-font-size-small;
    margin: auto 1rem;
  }
  .Toastify__close-button {
    margin: auto;
    color: $vg-white;
    svg {
      fill: $vg-white;
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shake {
  animation: shake 0.5s 1 linear;
}

kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
    0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}

@keyframes hide {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes hide {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  90% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  90% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    margin-left: 100vw;
  }
  to {
    margin-left: 0;
  }
}

@keyframes slide-out {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 100vw;
  }
}
