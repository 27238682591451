@import "assets/styles/Global/variables";

body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.flex-container {
  @include full-viewport-size;
}

.loading-container {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/images/light-static-bg.jpg");
  background-size: cover;
}

iframe {
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

@media (min-aspect-ratio: 16/9) {
  .flex-container {
    &.not-scaled {
      @include full-viewport-size;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      background-color: #000000;
      @include for-mobile {
        height: initial !important;
      }
    }
  }

  .bg-container {
    &.not-scaled {
      height: 100vh;
      width: calc(100vh * 16 / 9);
    }
  }
}

@media (max-aspect-ratio: 16/9) {
  .flex-container {
    &.not-scaled {
      @include full-viewport-size;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      background-color: #000000;
      @include for-mobile {
        height: initial !important;
      }
    }
  }

  .bg-container {
    &.not-scaled {
      width: 100vw;
      height: calc(100vw * 9 / 16);
    }
  }
}

@media only screen and (max-width: 900px) {
}
